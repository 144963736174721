// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-title {
  font-size: 1.8rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: center;
}

.new-thread {
  border: 1px solid #212121;
  padding: 10px;
  border-radius: 6px;
  background-color: #242424;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
}
.new-thread span:last-child {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;;EAEf,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".home-wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.home-title {\n  font-size: 1.8rem;\n  font-family: \"Courier New\", Courier, monospace;\n  font-weight: 700;\n  line-height: 2.4rem;\n  text-align: center;\n}\n\n.new-thread {\n  border: 1px solid #212121;\n  padding: 10px;\n  border-radius: 6px;\n  background-color: #242424;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  cursor: pointer;\n\n  font-size: 1rem;\n  font-weight: 400;\n  white-space: nowrap;\n}\n.new-thread span:last-child {\n  font-size: 1.2rem;\n  font-weight: 600;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
