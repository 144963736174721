// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #121212;
  color: #e1e1e1;
}

.App {
  height: 100%;
}

.root-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

input[type="text"] {
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #242424;
  border: 1px solid #313535;
  color: #e1e1e1;
  font-size: 16px;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  padding: 10px 20px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.source-btn {
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
}

.modal-form-wrapper {
  display: flex;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,SAAS;EACT,8BAA8B;EAC9B,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":["body,\nhtml {\n  height: 100%;\n  margin: 0;\n  font-family: Arial, sans-serif;\n  background-color: #121212;\n  color: #e1e1e1;\n}\n\n.App {\n  height: 100%;\n}\n\n.root-container {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  position: relative;\n}\n\ninput[type=\"text\"] {\n  width: 100%;\n  padding: 10px 20px;\n  border-radius: 20px;\n  background-color: #242424;\n  border: 1px solid #313535;\n  color: #e1e1e1;\n  font-size: 16px;\n}\n\ntextarea:focus,\ninput:focus {\n  outline: none;\n}\n\nbutton {\n  padding: 10px 20px;\n  background-color: #0d6efd;\n  color: white;\n  border: none;\n  border-radius: 20px;\n  font-size: 16px;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\n.source-btn {\n  text-decoration: underline;\n  margin-top: 10px;\n  cursor: pointer;\n}\n\n.modal-form-wrapper {\n  display: flex;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
