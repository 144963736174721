// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* + Sidebar Components */
.sidebar-container {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: 250px;
  border-right: 1px solid #212121;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #464e4e #181818;
}

.sidebar-wrapper {
  display: grid;
  gap: 10px;
}

.sidebar-top {
  padding: 20px;
}

.new-thread {
  border: 1px solid #212121;
  padding: 10px;
  border-radius: 6px;
  background-color: #242424;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
}
.new-thread span:last-child {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: auto;
}

.thread-list-wrapper {
  display: grid;
}

.thread-list {
  border-top: 1px solid #21212170;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  color: #a8a8a8;
}

.thread-list.active {
  background-color: #212121;
}

.loading-indicator {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/component/sidebar/sidebar.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,aAAa;EACb,gBAAgB;EAChB,+BAA+B;EAC/B,gBAAgB;;EAEhB,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;;EAEf,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,cAAc;EACd,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb","sourcesContent":["/* + Sidebar Components */\n.sidebar-container {\n  position: sticky;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  min-width: 250px;\n  border-right: 1px solid #212121;\n  overflow-y: auto;\n\n  scrollbar-width: thin;\n  scrollbar-color: #464e4e #181818;\n}\n\n.sidebar-wrapper {\n  display: grid;\n  gap: 10px;\n}\n\n.sidebar-top {\n  padding: 20px;\n}\n\n.new-thread {\n  border: 1px solid #212121;\n  padding: 10px;\n  border-radius: 6px;\n  background-color: #242424;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  cursor: pointer;\n\n  font-size: 1rem;\n  font-weight: 400;\n  white-space: nowrap;\n}\n.new-thread span:last-child {\n  font-size: 1.2rem;\n  font-weight: 600;\n  margin-left: auto;\n}\n\n.thread-list-wrapper {\n  display: grid;\n}\n\n.thread-list {\n  border-top: 1px solid #21212170;\n  padding: 15px 10px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  text-decoration: none;\n  color: inherit;\n  background-color: transparent;\n  color: #a8a8a8;\n}\n\n.thread-list.active {\n  background-color: #212121;\n}\n\n.loading-indicator {\n  padding: 20px;\n  text-align: center;\n  font-size: 16px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
