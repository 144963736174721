// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #121212; /* Dark background for the whole page */
  color: #e1e1e1; /* Light text color for better readability in dark mode */
}

.App {
  height: 100%;
}

/* - Top layout Components  */
.root-container {
  display: flex;
  gap: 1rem;
  position: relative;
}


input[type="text"] {
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #242424; /* Dark grey background for input field */
  border: 1px solid #313535; /* Slightly lighter border for contrast */
  color: #e1e1e1; /* Light text color for input */
  font-size: 16px;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  padding: 10px 20px;
  background-color: #0d6efd; /* Blue background for the send button */
  color: white; /* White text color */
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3; /* Darker blue on hover for visual feedback */
}

.source-btn {
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
}

.modal-form-wrapper {
  display: flex;
  gap: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/threads.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,SAAS;EACT,8BAA8B;EAC9B,yBAAyB,EAAE,uCAAuC;EAClE,cAAc,EAAE,yDAAyD;AAC3E;;AAEA;EACE,YAAY;AACd;;AAEA,6BAA6B;AAC7B;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;AACpB;;;AAGA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB,EAAE,yCAAyC;EACpE,yBAAyB,EAAE,yCAAyC;EACpE,cAAc,EAAE,+BAA+B;EAC/C,eAAe;AACjB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB,EAAE,wCAAwC;EACnE,YAAY,EAAE,qBAAqB;EACnC,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,6CAA6C;AAC1E;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":["body,\nhtml {\n  height: 100%;\n  margin: 0;\n  font-family: Arial, sans-serif;\n  background-color: #121212; /* Dark background for the whole page */\n  color: #e1e1e1; /* Light text color for better readability in dark mode */\n}\n\n.App {\n  height: 100%;\n}\n\n/* - Top layout Components  */\n.root-container {\n  display: flex;\n  gap: 1rem;\n  position: relative;\n}\n\n\ninput[type=\"text\"] {\n  width: 100%;\n  padding: 10px 20px;\n  border-radius: 20px;\n  background-color: #242424; /* Dark grey background for input field */\n  border: 1px solid #313535; /* Slightly lighter border for contrast */\n  color: #e1e1e1; /* Light text color for input */\n  font-size: 16px;\n}\n\ntextarea:focus,\ninput:focus {\n  outline: none;\n}\n\nbutton {\n  padding: 10px 20px;\n  background-color: #0d6efd; /* Blue background for the send button */\n  color: white; /* White text color */\n  border: none;\n  border-radius: 20px;\n  font-size: 16px;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #0056b3; /* Darker blue on hover for visual feedback */\n}\n\n.source-btn {\n  text-decoration: underline;\n  margin-top: 10px;\n  cursor: pointer;\n}\n\n.modal-form-wrapper {\n  display: flex;\n  gap: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
